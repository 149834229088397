import { useEffect } from "react";

const Home = () => {
    // initial render
    useEffect(() => {
        window.location = "/seed";
    }, []);

    return <></>;
};

export default Home;
